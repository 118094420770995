import { debug, isDevMode } from '@/const'
import { ApiCache } from '@/services/ApiCache'
import axios from 'axios' // Импортируем библиотеку axios
import md5 from 'blueimp-md5'

/**
 * Класс `ApiClient` для выполнения HTTP-запросов с использованием библиотеки Axios.
 * Предназначен для работы с API, включая настройку базового URL, установку заголовков,
 * логирование запросов и ответов, а также обработку ошибок.
 *
 * Методы:
 * - `fetch({ method: string, url: string, data?: Object, headers?: Object }, options?: { hotel?: boolean, cache?:
 * boolean }): Promise<Object>`: Выполняет HTTP-запрос и возвращает ответ сервера.
 * - `getHeaders(type?: string): Object`:
 *   Возвращает заголовки для запроса, включая токен авторизации и идентификатор отеля.
 *
 * Приватные методы:
 * - `#debug(attr: { method: string, url: string, data: Object, headers: Object, payload: Object }, type?: string):
 * void`: Логирует запросы и ответы в консоли в режиме разработки.
 * - `#getBaseURL(): string`:
 *   Возвращает базовый URL для API, сформированный на основе настроек окружения.
 *
 * Приватные поля:
 * - `#axios: AxiosInstance`: Экземпляр Axios для выполнения HTTP-запросов.
 * - `headerContentType: Object`: Объект с типами содержимого для заголовков (например, JSON, Multipart).
 *
 * Конструктор:
 * - Инициализирует экземпляр Axios с базовыми настройками, такими как базовый URL и заголовки по умолчанию.
 */
export class ApiClient {
    #axios            = axios // Приватное поле для axios
    headerContentType = {
        NULL: null,
        JSON: 'application/json; charset=utf-8',
        MULTIPART: 'multipart/form-data'
    }
    
    /**
     * Конструктор класса `ApiClient`.
     * Инициализирует экземпляр axios с предустановленными настройками.
     */
    constructor() {
        this.#axios                                         = axios // Инициализируем axios
        this.#axios.defaults.withCredentials                = false // Отключаем передачу cookie с запросами
        this.#axios.defaults.baseURL                        = this.#getBaseURL() // Устанавливаем базовый URL
        this.#axios.defaults.headers.post[ 'Content-Type' ] = 'application/json' // Устанавливаем заголовок по
        // умолчанию
    }
    
    /**
     * Приватный метод для логирования запросов и ответов в консоли.
     * Работает только в режиме разработки, если включен флаг `debug`.
     *
     * @param {Object} attr - Объект с информацией о запросе и ответе.
     * @param {string} attr.method - Метод HTTP-запроса (GET, POST и т.д.).
     * @param {string} attr.url - URL, на который был отправлен запрос.
     * @param {Object} attr.data - Данные, отправленные в запросе.
     * @param {Object} attr.headers - Заголовки, отправленные с запросом.
     * @param {Object} attr.payload - Ответ от сервера или ошибка.
     * @param {string} [type='log'] - Тип лога ('log', 'warn', 'error').
     */
    #debug( attr = { method: '', url: '', data: {}, headers: {}, payload: {} }, type = 'log' ) {
        if ( isDevMode && debug ) { // Проверка, включен ли режим отладки
            const now           = new Date()
            const isCache       = attr.method.toLowerCase() === 'cache'
            const consoleMethod = console[ type ] || console.log // Определяем метод консоли
            
            const statusMap = {
                log: 'OK',
                warn: 'WARN',
                error: 'ERROR'
            }
            const colorMap  = {
                log: 'color:green;',
                warn: 'color:yellow;',
                error: 'color:red;'
            }
            
            const status = isCache
                ? 'CACHE'
                : statusMap[ type ] || 'OK' // Определяем статус по типу лога
            
            const color = isCache
                ? 'color:gray;'
                : colorMap[ type ] // Определяем цвет лога
            
            // Начинаем группировку логов в консоли
            console.groupCollapsed( `%c [${ now.toLocaleString() }] [Fetch ${ status }] [URL: '${ attr.url }']`, color )
            
            console.groupCollapsed( '- CALL STACK' )
            console.trace()
            console.groupEnd()
            
            // Логируем метод запроса
            console.groupCollapsed( '- METHOD' )
            consoleMethod( attr.method )
            console.groupEnd()
            
            // Логируем URL
            console.groupCollapsed( '- URL' )
            consoleMethod( attr.url )
            console.groupEnd()
            
            // Логируем данные запроса
            console.groupCollapsed( '- REQUEST' )
            consoleMethod( attr.data )
            console.groupEnd()
            
            // Логируем заголовки запроса
            console.groupCollapsed( '- HEADERS' )
            consoleMethod( attr.headers )
            console.groupEnd()
            
            // Логируем ответ сервера
            console.groupCollapsed( '- RESPONSE' )
            consoleMethod( attr.payload )
            console.groupEnd()
            
            // Завершаем группировку логов
            console.groupEnd()
        }
    }
    
    /**
     * Приватный метод для получения базового URL для запросов.
     * URL может быть сформирован на основе настроек окружения или использоваться по умолчанию.
     *
     * @returns {string} - Базовый URL для API.
     */
    #getBaseURL() {
        // Регулярное выражение для проверки IP-адреса
        const ipformat = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
        
        // Получаем адрес сервера API
        let env_server_api = process.env.VUE_APP_SERVER_API || 'vitrina.stayday.com'
        // Получаем протокол (HTTP или // HTTPS)
        const protocol     = process.env.VUE_APP_SERVER_API_PROTOCOL || 'https://'
        
        // Проверяем, является ли адрес IP-адресом или "localhost"
        if ( env_server_api.match( ipformat ) || env_server_api === 'localhost' ) {
            env_server_api = `http://${ env_server_api }:5005` // Если да, используем HTTP и порт 5005
        }
        else {
            env_server_api = `${ protocol }${ env_server_api }` // Иначе, используем указанный протокол
        }
        
        return `${ env_server_api }/api` // Возвращаем полный URL
    }
    
    /**
     * Метод для выполнения HTTP-запроса.
     * Выполняет запрос и обрабатывает ответ, логируя результат и возвращая данные или ошибку.
     *
     * @param {Object} params - Параметры запроса.
     * @param {string} params.method - Метод HTTP-запроса (GET, POST и т.д.).
     * @param {string} params.url - URL, на который отправляется запрос.
     * @param {Object} [params.data] - Данные, отправляемые в теле запроса.
     * @param {Object} [params.headers] - Заголовки, отправляемые с запросом.
     * @param {Object} options - Опции запроса.
     * @param {boolean} [options.hotel] - Признак того, что при запросе headers содержит параметр hotel. По умолчанию
     *     false.
     * @param {boolean} [options.cache] - Признак того, что кешируем запрос. По умолчанию true.
     * @returns {Promise<Object>} - Промис, который разрешается с объектом ответа или отклоняется с ошибкой.
     */
    fetch(
        params  = {
            method: 'get',
            url: '',
            data: {},
            headers: []
        },
        options = {
            hotel: true,
            cache: true
        } ) {
        return new Promise( ( resolve, reject ) => {
            options        = { hotel: true, cache: true, ...options }
            const cacheKey = md5( JSON.stringify( params ) )// Ключ для кэширования запроса
            if ( params.method.toLowerCase() === 'get' && options.cache ) {
                const cache = ApiCache.get( cacheKey )
                if ( cache ) {
                    this.#debug( { ...params, method: 'cache', payload: cache }, 'log' )
                    return resolve( {
                        status: true,
                        error: false,
                        data: cache
                    } )
                }
            }
            if ( !options.hotel ) {
                delete params?.headers?.hotel
            }
            
            this.#axios( params )
                .then( ( response ) => {
                    const isSuccess = response.status === 200
                    
                    if ( params.method.toLowerCase() === 'get' && isSuccess && options.cache ) {
                        ApiCache.set( cacheKey, response.data ) // Записываем ответ в кэш
                    }
                    
                    this.#debug( { ...params, payload: response }, isSuccess
                        ? 'log'
                        : 'warn' )
                    resolve( {
                        status: isSuccess,
                        error: !isSuccess,
                        data: response.data || null
                    } )
                } )
                .catch( error => {
                    this.#debug( { ...params, payload: error }, 'error' )
                    reject( {
                        status: false,
                        error: true,
                        data: error?.response?.data || error?.response
                    } )
                } )
        } )
    }
    
    /**
     * Метод для получения заголовков авторизации.
     * Заголовки включают токен авторизации и идентификатор отеля.
     *
     * @param {string} [type='application/json; charset=utf-8']  - Тип содержимого заголовка.
     * @returns {Object} - Объект с заголовками для запроса.
     */
    getHeaders( type = this.headerContentType.JSON ) {
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem( 'token' ), // Получаем токен из локального хранилища
            'hotel': localStorage.getItem( 'hotel' ) // Получаем идентификатор отеля из локального хранилища
        }
        // Если тип содержимого задан, добавляем соответствующий заголовок
        if ( type ) {
            headers[ 'Content-Type' ] = type
        }
        
        return headers
    }
}
