import { User } from '@/services/User'
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use( VueRouter )

const routes = [
    {
        path: '/',
        name: 'main',
        blank: true,
        meta: { name: 'Вход' },
        component: () => import('../views/Pages/Login/Login.vue')
    },
    {
        path: '/login',
        name: 'login',
        blank: true,
        meta: {
            name: 'Вход'
        },
        component: () => import('../views/Pages/Login/Login.vue')
    },
    {
        path: '/registration',
        name: 'registration',
        blank: true,
        meta: {
            name: 'Регистрация'
        },
        component: () => import('../views/Pages/Register/Register.vue')
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        meta: {
            name: 'Главная',
            requiresAuth: true
        },
        component: () => import('../views/Pages/Dashboard/Dashboard.vue')
    },
    {
        path: '/users',
        name: 'users',
        meta: {
            name: 'Сотрудники',
            requiresAuth: true
        },
        component: () => import('../views/Pages/Users/Users.vue')
    },
    {
        path: '/orders',
        name: 'orders',
        meta: {
            name: 'Заказы',
            requiresAuth: true
        },
        component: () => import('../views/Pages/Orders/Orders.vue')
    },
    {
        path: '/timeslots',
        name: 'timeslots',
        meta: {
            name: 'Тайм - слоты',
            requiresAuth: true
        },
        component: () => import('../views/Pages/Timeslots/Timeslots.vue')
    },
    {
        path: '/settings',
        name: 'settings',
        meta: {
            name: 'Настройка объекта',
            requiresAuth: true
        },
        component: () => import('../views/Pages/Settings/Settings.vue')
    },
    {
        path: '/settings_system',
        name: 'settings_system',
        meta: {
            name: 'Настройка системы',
            requiresAuth: true
        },
        component: () => import('../views/Pages/SettingsSystem/SettingsSystem.vue')
    },
    {
        path: '/objects',
        name: 'objects',
        meta: {
            name: 'Объекты',
            requiresAuth: true
        },
        component: () => import('../views/Pages/Hotels/Hotels.vue')
    },
    {
        path: '/categories',
        name: 'categories',
        meta: {
            name: 'Категории',
            requiresAuth: true
        },
        component: () => import('../views/Pages/Categories/Categories.vue')
    },
    {
        path: '/guests',
        name: 'guests',
        meta: {
            name: 'Гости',
            requiresAuth: true
        },
        component: () => import('../views/Pages/Guests/Guests.vue')
    },
    {
        path: '/items',
        name: 'items',
        meta: {
            name: 'Позиции',
            requiresAuth: true
        },
        component: () => import('../views/Pages/Items/Items.vue')
    },
    {
        path: '/request',
        name: 'Заявки',
        meta: 'Заявки',
        component: () => import('../views/Pages/Proposal/Proposal.vue')
    },
    {
        path: '/error',
        name: '404 - Страница не найдена',
        meta: '404 ошибка',
        component: () => import('../views/Pages/Error/Error.vue')
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'pageNotFound',
        meta: {
            name: 'Страница не найдена',
            requiresAuth: false
        },
        component: () => import('../views/Pages/Error/Error.vue')
    }
]

const router = new VueRouter( {
    routes,
    // scrollBehavior(to, from, savedPosition) {
    //     if (savedPosition) {
    //         return savedPosition;
    //     } else {
    //         return { x: 0, y: 0 };
    //     }
    // }
} )

/**
 * Перед каждым переходом маршрута проверяет, авторизован ли пользователь.
 * Если пользователь авторизован, перенаправляет на дашборд при переходе на страницу логина или регистрации.
 * Если не авторизован, перенаправляет на страницу логина при попытке перейти на защищенные маршруты.
 *
 * @param {Object} to - Маршрут, на который переходит пользователь.
 * @param {Object} from - Маршрут, с которого уходит пользователь.
 * @param {Function} next - Функция, которая завершает навигационный процесс.
 */
router.beforeEach( ( to, from, next ) => {
    
    // Получаем объект пользователя из приложения или локального хранилища.
    let user = router.app.user || User.getLocalUser() || null
    
    // Определяем, авторизован ли пользователь.
    const isLogged = Boolean( user )
    
    // logger( '[Router.index]', 'Auth data:', {
    // 	isLogged,
    // 	to: to.name,
    // 	user
    // } )
    
    // Проверяем, если пользователь пытается перейти на страницы логина или регистрации.
    if ( [ 'login', 'registration', 'main' ].includes( to.name ) ) {
        // Если пользователь авторизован, перенаправляем на дашборд.
        if ( isLogged ) {
            return next( { path: '/dashboard' } )
        }
    }
    else {
        // Если пользователь не авторизован, перенаправляем на страницу логина.
        if ( !isLogged ) {
            return next( { path: '/login' } )
        }
    }
    
    // Завершаем переход на целевой маршрут.
    next()
} )
export default router
