import { debug, isDevMode } from '@/const'

export function isCancelFee( hotel, orderRoom = '' ) {
    
    const orderRoomStr = String( orderRoom ).toLocaleLowerCase().trim()
    const room         = orderRoomStr || ''
    
    const settingType  = hotel?.settings?.cancel_fee_type || null
    const settingValue = hotel?.settings?.cancel_fee_value || null
    
    const isCancelFee  = settingType === 'room' && settingValue === 'pickup'
    const isRoomPickUp = room === 'pickup'
    
    return isCancelFee && isRoomPickUp
}

/*
 * Close offcanvas
 * @param el - offcanvas element
 * @param cb - callback function to be called when offcanvas is closed
 * */
export function closeOffcanvas( el = HTMLElement, cb = null ) {
    const isVueComponent = !!el._isVue
    
    const offcanvasEl = el
    const offcanvas   = isVueComponent
        ? el
        : window?.bootstrap?.Offcanvas?.getInstance( offcanvasEl )
    
    offcanvas?.hide()
    const isOpen = isVueComponent
        ? offcanvas?.isOpen
        : offcanvas?._isShown
    
    const checkInterval = setInterval( () => {
        if ( !isOpen ) {
            clearInterval( checkInterval )
            if ( typeof cb === 'function' ) {
                setTimeout( cb, 500 )
            }
        }
    }, 250 )
}

export function logger( ...args ) {
    if ( isDevMode && debug ) {
        console.log( ...args )
    }
}

export function viewLogger( mount, componentName, component ) {
    if ( isDevMode && debug ) {
        const label    = mount
            ? 'Mount'
            : 'Unmount'
        const colorMap = {
            true: 'color:blue;',
            false: 'color:#dcaf00'
        }
        const color    = colorMap[ mount ]
        
        if (!mount){
            console.clear()
        }
        
        const now = new Date()
        console.groupCollapsed( `%c [${ now.toLocaleString() }] [${ label }] [Component: ${ componentName }]`, color )
        console.log( component )
        console.groupEnd()
    }
}
